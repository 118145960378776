<template>
  <div>
    <div class="row">
      <div class="icon-wrapper cursor-pointer" @click="telecharger">
        <b-button variant="outline-primary" size="sm" class="ml-2 mb-2">
          Rélevé du {{ date }}
        </b-button>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <b-card-code>
          <div>
            <span class="font-weight-black"> {{ toPrint.type }} : </span>
            {{ toPrint.concerne }}
          </div>

          <hr />
          <div v-if="!toPrint.showMontantColumn">
            <span class="font-weight-black"> Solde actuel du compte : </span>
            {{ $thousandSeparator(toPrint.solde) }}
          </div>
          <div>
            <span class="font-weight-black"> Du : </span>
            {{
              toPrint.periodeDu ? reformatDate(toPrint.periodeDu) : periodeDu
            }}
          </div>
          <div>
            <span class="font-weight-black"> Au : </span>
            {{
              toPrint.periodeAu
                ? reformatDate(toPrint.periodeAu)
                : "------------"
            }}
          </div>
        </b-card-code>
      </div>
      <div class="col-6">
        <b-card-code>
          <div v-if="toPrint.compteDe">
            <span class="font-weight-black"> Compte : </span>
            {{ toPrint.compteDe }}
          </div>
          <div v-if="toPrint.avecCaisseDe">
            <span class="font-weight-black"> Caisse concernée : </span>
            {{ toPrint.avecCaisseDe }}
          </div>
          <div v-if="toPrint.avecSocieteDe">
            <span class="font-weight-black"> Société concernée : </span>
            {{ toPrint.avecSocieteDe }}
          </div>

          <div>
            <span class="font-weight-black"> Total des entrées : </span>
            {{ $thousandSeparator(totalEntree) }}
          </div>
          <div>
            <span class="font-weight-black"> Total des sorties : </span>
            {{ $thousandSeparator(totalSortie) }}
          </div>
          <div>
            <span class="font-weight-black"> Solde de la période : </span>
            {{ $thousandSeparator(totalSolde) }}
          </div>
        </b-card-code>
      </div>
    </div>
    <div class="row">
      <div class="d-flex flex-column mx-1">
        <div>
          <b-table responsive :fields="fields" :items="toPrint.journal">
            <template #cell(entree)="data">
              <div>
                {{
                  data.item.entree
                    ? $thousandSeparator(data.item.entree)
                    : "-----"
                }}
              </div>
            </template>
            <template #cell(sortie)="data">
              <div>
                {{
                  data.item.sortie
                    ? $thousandSeparator(data.item.sortie)
                    : "-----"
                }}
              </div>
            </template>
            <template #cell(solde)="data">
              <div>
                {{
                  data.item.solde
                    ? $thousandSeparator(data.item.solde)
                    : "-----"
                }}
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BPagination,
  BBadge,
  BFormSelect,
  BFormCheckbox,
  BRow,
  BCol,
  BContainer,
  BTable,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BAlert, BLink } from "bootstrap-vue";

export default {
    components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    EmptyList,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BRow,
    BCol,
    BBadge,
    BContainer,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BLink,
  },
  data: () => ({
    toPrint: {},
    showSortie: true,
    SocietePrincipale: {},
    fields: [
        { key: "created_at", label: "Date de l'opération" },
        { key: "libelle", label: "Libellé" },
        { key: "compte_tiers", label: "Association/Importateur" },
        { key: "entree", label: "Entrée" },
        { key: "sortie", label: "Sortie" },
        { key: "solde", label: "Solde" },
      ],
  }),

  computed: {
    periodeDu() {
      let minDate
      let journal = this.toPrint.journal ? this.toPrint.journal : []
      // console.log(journal)
      if (journal.length > 0) {
        minDate = journal[0].created_at
        journal.forEach((element) => {
          if (element.created_at < minDate) minDate = element.created_at
        })
      }
      return minDate
    },

    date() {
      var today = new Date()
      let annee = today.getFullYear()
      let mois = ('0' + (today.getMonth() + 1)).slice(-2)
      let jour = ('0' + today.getDate()).slice(-2)
      var date = jour + '/' + mois + '/' + annee
      return date
    },

    totalEntree() {
      let entree = 0
      if (this.toPrint.journal) {
        this.toPrint.journal.forEach((element) => {
          entree += element.entree
        })
      }
      return entree
    },

    totalSortie() {
      let sortie = 0
      if (this.toPrint.journal) {
        this.toPrint.journal.forEach((element) => {
          sortie += element.sortie
        })
      }
      return sortie
    },

    totalSolde() {
      return this.totalEntree - this.totalSortie
    },

    cc() {
      return this.hasPersonnel() ? 'Personnel Concerné' : 'Caisses concernées'
    },

    hasSociete() {
      let counter = 0
      if (this.toPrint.headers) {
        this.toPrint.headers.forEach((element) => {
          if (element.value == 'societe') counter++
        })
      }
      return counter > 0 ? true : false
    },

    hasFsr() {
      let counter = 0
      if (this.toPrint.headers) {
        this.toPrint.headers.forEach((element) => {
          if (element.value == 'retirer_par') counter++
        })
      }
      return counter > 0 ? true : false
    },

    hasSource() {
      let counter = 0
      if (this.toPrint.headers) {
        this.toPrint.headers.forEach((element) => {
          if (element.value == 'source') counter++
        })
      }
      return counter > 0 ? true : false
    },
  },

  mounted() {
    let data = this.$router.currentRoute.params.data
    if (data && !data.showSoldeColumn) {
      if (!data.showMontantColumn) {
        data.headers.pop()
      } else {
        this.showSortie = false
      }
    }
    this.toPrint = data
    this.societesData()
  },

  methods: {
    reformatDate(date) {
      if (date) {
        let annee = date.getFullYear()
        let mois = ('0' + (date.getMonth() + 1)).slice(-2)
        let jour = ('0' + date.getDate()).slice(-2)
        let date = jour + '/' + mois + '/' + annee
        return date.toString()
      }
      return date
    },

    hasPersonnel() {
      let counter = 0
      if (this.toPrint.headers) {
        this.toPrint.headers.forEach((element) => {
          console.log(element.text == 'Personnel')
          if (element.text == 'Personnel') counter++
        })
      }
      return counter > 0 ? true : false
    },

    async societesData() {
      let response = await this.$http.get('/societes').then((response) => {
        this.SocietePrincipale = response.data.data[0]
        this.SocietePrincipale = this.SocietePrincipale
          ? this.SocietePrincipale
          : {
              name: 'STE PAPETERIA SARL',
              address: 'Sekandji',
              ifu: '-',
              rccm: '-',
              telephone: '97201630',
            }
        // console.log('this.SocietePrincipale', this.SocietePrincipale)
      })
    },


    reformatDate(date) {
      if (date) {
        date = new Date(date)
        let annee = date.getFullYear()
        let mois = date.getMonth() + 1
        let jour = date.getDate()
        date = jour + '/' + mois + '/' + annee
        return date.toString()
      }
      return date
    },

    telecharger() {
      window.print()
      //  window.close();
    },
  },
}
</script>

<style lang="scss">
@page {
    size: landscape;
}

.img {
  width: 50% !important;
}
</style>
